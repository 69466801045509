$loginPageWidth: 800px;
$loginPageHeight: 432px;

.login {
    width: $loginPageWidth;
    height: $loginPageHeight;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -#{$loginPageHeight / 2} 0 0 -#{$loginPageWidth / 2};
    padding: 1rem;

    > form {
        margin-top: 1rem;
    }

    &.modal {
        position: fixed;
        z-index: 1000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        background: rgba(64, 104, 170, 0.8);
    }
}
