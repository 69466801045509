@import 'navigation';

.page {
  padding: 2em;
  display: flex;


  > .content {
    flex-basis: 100%;
  }
}

body {
    &.is-busy {
        cursor: wait;

        * {
            cursor: wait;
            pointer-events: none;
        }
    }

    &.modal-open {
        overflow: hidden;
    }

    &.dev-host {
        background-image: linear-gradient(-45deg, rgb(170, 64, 78), rgb(96, 36, 58));
    }
    &.staging-host {
        background-image: linear-gradient(-45deg, rgb(70, 170, 64), rgb(38, 102, 41));
    }
}

.not-allowed {
    cursor: not-allowed;
}

div, span, dd, dt, li {
    .disabled {
        opacity: 0.4;
    }
}
