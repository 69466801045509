.content {
    &.view,
    &.details,
    &.sketch,
    &.medicalcases[class*="add"], &.medicalcases[class*="insert"],
    &.medicalcases[class*="edit"], &.medicalcases[class*="update"],
    &.reports[class*="edit"], &.reports[class*="update"],
    &.treatments {
        dl {
            dt {
                margin-top: 1em;
                padding-bottom: 0.1em;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .card-block + .card-block {
            border-top: 2px solid $hr-border;
            padding-top: 2em;

            h1, .h1, h2, .h2, h3, .h3 {
                &:first-child {
                    margin-top: 0;
                }
            }
        }
    }
}

.modulelist {
    .panel-heading {
        @extend .h5;
    }
}

/* reports */
.content {
    &.reports {
        &.edit, &.view {
            fieldset {
                legend {
                    @extend h4;
                    border-bottom: none;
                }

                .form-group {
                    padding-left: 20px;
                }
            }
        }
    }
}
