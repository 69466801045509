.circled-icon {
    display: inline-block;
    border: 1px solid;
    border-radius: 50%;
    margin: 0 .1em;
    white-space: nowrap;
    text-align: center;
    padding-top: 2px;
    line-height: 1;

    min-width: 20px;

    &.circled-icon-primary {
        @include alert-variant($brand-primary, $brand-primary, #fff);
    }

    &.circled-icon-success {
        @include alert-variant($alert-success-bg, $alert-success-border, $alert-success-text);
    }

    &.circled-icon-info {
        @include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
    }

    &.circled-icon-warning {
        @include alert-variant($alert-warning-bg, $alert-warning-border, $alert-warning-text);
    }

    &.circled-icon-danger {
        @include alert-variant($alert-danger-bg, $alert-danger-border, $alert-danger-text);
    }
}
