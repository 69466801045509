[class|=col-] {
    .radio, .checkbox {
        margin-top: floor($font-size-base * 2);
    }
}

fieldset {
    margin-bottom: floor($font-size-base * 2);
}

.form-group.password {
    position: relative;

    .btn {
        position: absolute;
        right: 0;
        top: 0;
        height: $input-height-base;
        background: transparent;

        &:before {
            font-family: 'icons';
        }
    }

    label ~ .btn {
        top: ($line-height-computed + 5);
    }

    input {
        padding-right: 2.5em;

        &[type="password"] + .btn:before {
            content: getIconChar('eye');
        }

        &[type="text"] + .btn:before {
            content: getIconChar('eye-hide');
        }
    }
}

// checkbox button
.checkbox-btn-list {
    .checkbox {
        label {
            @extend .btn-default;
            border: 1px solid $btn-default-border;
            border-radius: $btn-border-radius-base;
            min-width: 80px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 23px;
            padding-right: 23px;
        }

        input[type="checkbox"] {
            display: none;
        }

        input[type="checkbox"]:checked + label {
            @extend .btn-primary;
            padding-right: 6px;
            &:after {
                display: inline-block;
                width: 12px;
                content: "\2714";
                margin-left: 5px;
            }
        }
    }
}
.checkbox-btn-list.has-error {
    .checkbox {
        label {
            border-color: #a94442;
        }
    }
}

// radio/checkbox button groups
.radio-btn-group, .checkbox-btn-group {
    display: block;
    @include clearfix;

    label {
        span.btn {
            @extend .btn-default;
            opacity: 0.65;
            min-width: 80px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 23px;
            padding-right: 23px;
        }

        input[type="radio"]:checked + span.btn {
            @extend .btn-primary;
            opacity: 1;
            padding-left: 17px;
            padding-right: 12px;

            &:after {
                display: inline-block;
                width: 12px;
                content: "\2714";
                margin-left: 5px;
            }
        }

        input[type="radio"]:disabled {
            + span.btn {
                @extend .disabled;
            }
        }

        &:not(:first-child) span.btn {
            margin-left: -1px;
        }

        &:not(:first-child):not(:last-child) span.btn {
            border-radius: 0;
        }

        &:first-child:not(:last-child) span.btn {
            @include border-left-radius($btn-border-radius-base);
            @include border-right-radius(0);
        }

        &:last-child:not(:first-child) span.btn {
            @include border-right-radius($btn-border-radius-base);
            @include border-left-radius(0);
        }
    }

    input[type="radio"], input[type="checkbox"] {
        position: absolute;
        clip: rect(0, 0, 0, 0);
        pointer-events: none;
    }
}

/* suggest lists */
.form-control {
    ~ .input-suggest {
        width: 100%;
        margin: 0;
        position: absolute;
        z-index: $zindex-dropdown;
        left: 0;
        top: 33px;

        ul {
            position: absolute;
            width: 100%;
            padding: 0;
            list-style: none;
            border: 1px solid #ccc;
            background: #fff;
            max-height: 200px;
            overflow-y: auto;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            border-radius: 5px;

            li {
                cursor: pointer;
                border-bottom: 1px solid $input-border;
                padding: 5px;

                &.active {
                    color: #fff;
                    background-color: $brand-primary;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    + .input-group-addon + .input-suggest, .input-group-addon + & + .input-suggest {
        display: table-row;
    }

    &.input-table-date {
        @extend .input-sm;

        max-width: 100px;
        padding-top: 0;
        padding-bottom: 0;
    }

    /* für Eingabe-Elemente, die wie die Krankenkassensuchfelder
    nur zur Steuerung des Formulars dienen und nicht gespeichert werden */
    &.input-void {
        background-color: #eee;
    }
}

.suggest-result {
    @extend .form-control;
    @extend .form-control-static;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

// file content readonly textareas
textarea[readonly].file-content {
    height: 400px;

    &, &-small {
        font-family: 'Courier New', monospace;
        font-size: 11px;
        width: 600px;
    }

    &-small {
        height: 100px;
    }
}

// file upload
.form-group.file {
    position: relative;

    > input[type=file] {
        display: none;
    }

    input[type=text] {
        padding-right: 2.5em;
        background: $input-bg;
    }

    .btn {
        position: absolute;
        right: 0;
        top: 0;
        height: $input-height-base;
        background: transparent;

        &:before {
            font-family: 'icons';
            content: getIconChar('upload-solid');
        }
    }

    label ~ .btn {
        top: ($line-height-computed + 5);
    }

}
