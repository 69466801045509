.tooltip-container {
    background: $brand-primary;
    color: #fff;
    padding: .5em;
    margin-bottom: .2em;
    z-index: 10;
    font-weight: normal;

    :after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
    }

    &[x-placement=top] {
        text-align: center;

        :after {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 5px solid $brand-primary;
            bottom: -5px;
            left: 50%;
            margin-left: -6px;
        }
    }

    &[x-placement=bottom] {
        text-align: center;

        :after {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 5px solid $brand-primary;
            top: -5px;
            left: 50%;
            margin-left: -6px;
        }
    }

    &[x-placement=left] {
        margin-right: 10px;
        text-align: left;

        :after {
            border-left: 5px solid #4068AA;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            top: 50%;
            right: 0;
            margin-top: -6px;
            margin-right: -5px;
        }
    }

    &[x-placement=right] {
        margin-left: 10px;
        text-align: right;

        :after {
            border-right: 5px solid #4068AA;
            border-top: 6px solid transparent;
            border-bottom: 6px solid transparent;
            top: 50%;
            left: 0;
            margin-top: -6px;
            margin-left: -5px;
        }
    }
}
