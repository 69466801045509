.alert {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
    border-left-width: 5px;

    .content .card & {
        box-shadow: none;
        background-color: transparent;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: none;
        border-bottom: none;
        border-right: none;
    }
}
