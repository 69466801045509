h6 {
    font-size: 100%;
}

a, button.btn-link {

    &:hover, &:focus, &:active {
        text-decoration: none;
        outline: none;
    }
}

.btn-link {
    padding: 0;
    border: 0px;
}

abbr[title], abbr[data-original-title] {
    text-decoration: none;
}

.xdebug-var-dump {
    width: 100%;
    padding: 1em;
    background: $white;
    overflow: auto;
}
