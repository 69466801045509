$white: #fff;

$brand-primary: #4068AA;
$panel-primary-text: $brand-primary;
$panel-footer-bg: transparent;

$font-size-base: 14px;

$alert-border-radius: 0;

@import 'bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/normalize';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/scaffolding';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/type';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/tables';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/buttons';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/pagination';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/alerts';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/close';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/list-group';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/labels';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/panels';
@import 'bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';

@import 'mixins';
@import 'modules/debug';
@import 'modules/typo';
@import 'modules/hr';
@import 'modules/icons';
@import 'modules/circled-icons';
@import 'modules/card';
@import 'modules/tables';
@import 'modules/tooltips';
@import 'modules/alert';
@import 'modules/sidepanel';
@import 'modules/multiselect';
@import 'modules/forms';
@import 'modules/lists';
@import 'modules/filtertags';
@import 'modules/panels';
@import 'modules/pagination';
@import 'modules/card-reader';
@import 'modules/buttons';

@import 'content/content';

@import 'layout/layout';
@import 'pages/login';
@import 'pages/error';

* {
  box-sizing: border-box;
}

html {
  min-height: 100%;
  height: auto;
}

body {
  min-height: 100%;
  background: linear-gradient(-45deg, $brand-primary, darken($brand-primary, 20%));
  background-size: cover;
  overflow-y: scroll;
}

@import "print";
