.panel {
    border: none;

    box-shadow: none;
    border-radius: 0;
    @include box-shadow(none);

    .panel-heading {
        @extend .h4;
        background-color: transparent;
        padding-left: 0;
        margin-bottom: 0;
        border-bottom: none;
    }

    .panel-body {
        border-left: 5px solid $panel-inner-border;

        padding-top: ceil($panel-body-padding / 2);
        padding-bottom: 0;
    }
}

.panel-body {
    border-left-color: $panel-primary-border;

    // Contextual variations
    .panel-primary & {
        border-left-color: $panel-primary-border;
    }

    .panel-success & {
        border-left-color: $panel-success-border;
    }

    .panel-info & {
        border-left-color: $panel-info-border;
    }

    .panel-warning & {
        border-left-color: $panel-warning-border;
    }

    .panel-danger & {
        border-left-color: $panel-danger-border;
    }

}

.panel-footer {
    border-left: 5px solid $panel-inner-border;
    border-top: 0;
    padding-top: 0;

    .actions a {
        margin-left: 1em;

        &:first-child {
            margin-left: 0;
        }
    }
}
