.sidepanel {
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100vh;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
    background: $white;
    padding: 2em;
    border-left: 5px solid $brand-primary;

    display: none;
}

.sidepanel.open {
    display: block;
    min-width: 50vw;
    z-index: 5;

    + .backdrop {
        display: block;
        position: fixed;
        z-index: 0;
        background: rgba(0, 0, 0, .5);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

    }

    [data-sidepanel-content] {
        display: inherit;
        visibility: visible;
    }
}

[data-sidepanel-content] {
    display: none;
    visibility: hidden;
}
