// set page print margins
@page {
    size: auto;
    margin: 10mm;
}

@media print {
    body {
        background: white;
    }

    .page {
        padding: 0;
    }

    .card {
        border-left: none;
        box-shadow: none;
        padding: 0;
    }

    .card-header {
        .actions {
            display: none;
        }
    }

    .table {
        td.actions, th.actions {
            display: none;
        }
    }

    .alert {
        box-shadow: none;

        button {
            display: none;
        }
    }
}
