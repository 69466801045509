.card {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 0;
  background: $white;
  padding: 2em;
  border-left: 5px solid $brand-primary;

  &.card-danger {
      border-color: $brand-danger;

      h1, h1 small,
      h2 {
          color: $brand-danger;
      }
  }
}

.card-header {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    h1, h2, h3 {
        flex: 1 1 auto;
        margin: 0;
    }
    .actions {
        flex: 0 1 auto;
        min-width: 310px;
        text-align: right;

        a {
            margin-left: 1em;

            &:first-child {
                margin-left: 0;
            }
        }
    }
    .icon:before {
        position: relative;
        top: .1em;
        margin-right: .3em;
    }
}

.card-block {
    margin: 2em 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .dashboard & .flex-col {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}
