.filter-tags {
    margin-bottom: .5em;
    .label {
        margin-left: 0.5em;
        font-size: 12px;

        &:first-child {
            margin-left: 0;
        }

        &-default a {
            color: $white;
            font-size: 11px;
        }
    }

    a {
        margin-left: .2em;
    }
}
