ul, ol {
    &.sortable {
        li:first-child .move-up {
            visibility: hidden;
        }
        li:last-child .move-down {
            visibility: hidden;
        }
    }

    li .list-number {
        @extend .pull-left;

        margin-right: 10px;

        &:after {
            content: ")";
        }
    }
}

.list-group {
    .panel {
        margin-top: 22px;
        margin-bottom: 0;
    }

    .list-group {
        margin-bottom: 0;
    }
}

.list-group-item-text > .list-group > .list-group-item {
    border: 0;
}

.list-group-item p:last-child {
    margin-bottom: 0;
}

.panel {
    dl {
        margin-bottom: 0;
    }

    dd {
        margin-bottom: ($line-height-computed / 2);

        .list-group {
            margin-bottom: 0;
        }
    }
}
