.page.error {
    width: 800px;
    height: 400px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -200px 0 0 -400px;
    padding: 1rem;

    &_dev {
        width: 95%;
        height: auto;
        position: static;
        margin: 0 auto;
    }

    .card-block {
        h1,h2,h3,h4,h5 {
            color: $text-color;
        }
    }
}

.error_dev {
    .toggle-link {
        @extend .btn;
        @extend .btn-default;
        margin-bottom: 1em;
    }
    .excerpt-number:after {
        content: attr(data-number);
    }

    .code-excerpt {
        display: block;
        margin: 5px 0;
        background: $white;
    }
    .code-highlight {
        display: block;
        background: $brand-info;
    }
    .excerpt-line {
        padding-left: 2px;
    }
    .excerpt-number {
        width: 50px;
        text-align: right;
        color: $gray-light;
        border-right: 1px solid $gray-light;
        padding: 2px;
    }
    .excerpt-number:after {
        content: attr(data-number);
    }
}

.cake-error {
    background-color: rgba(255,255,255,0.7);
}
