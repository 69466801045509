.page-navigation {
    flex: 0 0 300px;
    margin-right: 2em;
    padding: 2em;
    background: $white;
    border-left: 5px solid $brand-primary;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);

    header {
        margin: 0 0 2em;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    a {
        color: $text-color;
        display: block;

        &:focus,
        &:hover {
            text-decoration: none;
            color: $brand-primary;
        }
    }

    .navigation-icon {
        margin-bottom: 1em;

        ul {
            display: flex;
            margin: 0 -2em;
        }

        li {
            flex: 1 1 auto;
            text-align: center;
        }

        a {
            display: inline-block;
            color: $brand-primary;
            &:before {
                display: block;
                width: 1.5em;
                height: 1.5em;
                font-family: 'icons';
            }
        }

        .message:before {
            content: getIconChar('bell');
        }

        .user:before {
            content: getIconChar('user');
        }

        .help:before {
            content: getIconChar('question');
        }

        .signout:before {
            content: getIconChar('sign-out');
        }
    }

    .navigation-main {
        ul {
            margin: 0 -2em 0 $font-size-base * -2;

            li {
                padding: 0 2em 0 $font-size-base * 2;
                &:not(.active) {
                    padding: 0 2em 0 $font-size-base * 2;
                }
            }
        }

        a {
            padding: .5em 0;
        }

        .has-submenu {
            position: relative;

            &.active {

            }

            > a {
                font-weight: 700;
                color: $brand-primary;

                &:after {
                    font-family: 'icons';
                    content: getIconChar('angle-right');
                    display: block;
                    position: absolute;
                    right: 2em;
                    top: .5em;
                    height: 1em;
                    cursor: pointer;
                    color: $text-color;
                }
            }

            > ul {
                display: none;
            }

            &.open {
                > ul {
                    display: inherit;

                    > .active {
                        background: $brand-primary;
                        a {
                            color: $white;
                        }
                    }
                }

                > a:after {
                    content: getIconChar('angle-down');
                }
            }
        }
    }
}
