select[multiple] {
    display: none;
}

.multiselect {
    @include make-row();

    label {
        margin-top: .3em;
        font-weight: 400;
    }

    .selectableList, .selectionList {
        height: 10em;
        overflow-y: scroll;
        list-style: none;
        padding: $padding-base-vertical $padding-base-horizontal;
        border: 1px solid $input-border;
        border-radius: $input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.
        @include box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
        @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);

        li {
            padding: 0.3em 0;
            border-bottom: 1px solid $table-border-color;

            &:hover {
                color: $brand-primary;
                cursor: pointer;
            }
            cursor: pointer;
        }
    }

    .has-error &{
        .selectableList, .selectionList {
            border-color: #a94442;
        }
    }

    input.form-control {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 0;

        + .selectableList, + .selectionList {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }
}
