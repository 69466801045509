.table {
    thead {
        th {
            border-top: 0;
            border-bottom-color: $brand-primary;
            color: $brand-primary;
            white-space: nowrap;

            a {
                position: relative;
                display: block;
                &.asc, &.desc {
                    padding-left: 15px;
                    &:before {
                        position: absolute;
                        top: .2em;
                        left: 0;
                        display: block;
                        width: 1em;
                        height: 1em;
                        font-family: 'icons';
                    }
                }

                &.asc:before {
                    content: getIconChar('sort-asc');
                }

                &.desc:before {
                    content: getIconChar('sort-desc');
                }
            }
        }
        td.numeric, th.numeric {
            text-align: right;
        }
    }
    tbody {
        tr.disabled {
            opacity: .4;
        }

        td.actions, td.status {
            white-space: nowrap;
            text-align: right;
            width: 10%;
            position: relative;
            a {
                @extend .hide-text;
                &:before {
                    padding: .2em;
                }
                &:first-child:before {
                    padding: 0;
                }
            }

            i[class|=icon] {
                @extend .hide-text;
                &:before {
                    padding: .2em;
                }
            }
        }
        td.status + td.actions {
            width: auto;
        }
        td.numeric, th.numeric {
            text-align: right;
        }

        th small {
            font-weight: normal;
        }
    }
}

table {
    .insurances.index & {
        @include fixtable(10%, auto, 20%, 20%);
    }
    .practices.index & {
        @include fixtable(auto, 12%, 10%, 10%, 7%, 10%);
    }
    .opss.index &,
    .icds.index & {
        @include fixtable(10%, auto);
    }
}
